var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "property-amenities-slider" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "property-amenities-slider__slick" },
      [
        _c(
          "span",
          {
            staticClass: "property-amenities-slider__arrow",
            attrs: { id: "slick-prev-review" },
          },
          [_c("SvgChevronLeft")],
          1
        ),
        _c(
          "Slick",
          { attrs: { options: _vm.slickOptions } },
          _vm._l(Object.values(_vm.amenities), function (at, index) {
            return _c(
              "div",
              {
                key: index,
                class:
                  "property-amenities-slider__icon amenity " +
                  (_vm.icons["amenities"][at.id] !== undefined
                    ? _vm.icons["amenities"][at.id]
                    : "icon-default"),
                on: { click: _vm.handlerModalAmenities },
              },
              [_c("span", { domProps: { innerHTML: _vm._s(at.name) } })]
            )
          }),
          0
        ),
        _c(
          "span",
          {
            staticClass: "property-amenities-slider__arrow",
            attrs: { id: "slick-next-review" },
          },
          [_c("SvgChevronRight")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "property-amenities-slider__header" }, [
      _c("h3", { staticClass: "property-amenities-slider__title mr-2" }, [
        _vm._v("Informazioni importanti"),
      ]),
      _c("p", { staticClass: "property-amenities-slider__subtitle" }, [
        _vm._v("Consulta le seguenti categorie di informazioni"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }