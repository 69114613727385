<template>
    <div id="property-details" data-e2e="propertyDetails">
        <div v-if="loadingProperty" class="hide-multiple-spinner">
            <Spinner />
        </div>

        <div v-else>
            <div class="property">
                <b-row class="propertyHeading">
                    <b-col class="topBtn col-12">
                        <div @click="goBack()"><BtnBack :text="'Torna indietro'" /></div>
                    </b-col>

                    <b-col v-if="mixinGetIntegrationInfo(0).mode == 'boxset'" lg="12" xl="3" class="boxsetPadPropertyColumn">
                        <div class="boxsetPadProperty">
                            <span v-b-popover.hover.top="'Le disponibilità sono reali e, quindi, dinamiche. Acquista questo voucher ora se vuoi prenotare subito questa struttura'"><SvgInfoCircle class="infoCircle" /></span>
                            Con il Salabam
                            <span>{{focus | capitalize}} </span>
                            <span>{{propertyBand | uppercase}} </span>
                            <span>{{nights}} </span><span v-if="nights==1">notte </span><span v-else>notti </span>
                            potrai prenotare:
                            <div class="triangleRight"></div>
                        </div>
                    </b-col>

                    <b-col md="8" :xl="mixinGetIntegrationInfo(0).mode == 'boxset' ? 6 : 8" class="topProperty">
                        <div class="property__top">
                            <div>
                                <div class="d-flex align-items-center">
                                    <h3 class="property__heading mr-2">{{property.name}}</h3>
                                    <Stars :hotelRating="parseFloat(property.stars.intval+'.'+property.stars.floatval)" />
                                </div>

                                <!-- obfuscated è diventato obfuscation_required in ra2.4 con questo trick non devo fare una nuova versione-->
                                <p class="property__address" v-if="!property.address.obfuscated && !property.address.obfuscation_required">
                                    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.49988 0.286011C2.69988 0.286011 0.399902 2.58605 0.399902 5.38605C0.399902 8.18605 3.39988 11.386 5.49988 13.186C7.59988 11.386 10.5999 8.18605 10.5999 5.38605C10.5999 2.58605 8.29988 0.286011 5.49988 0.286011ZM5.49988 6.58606C4.39988 6.58606 3.59985 5.68604 3.59985 4.68604C3.59985 3.68604 4.49988 2.78601 5.49988 2.78601C6.49988 2.78601 7.3999 3.68604 7.3999 4.68604C7.3999 5.68604 6.49988 6.58606 5.49988 6.58606Z" fill="#0071BC"/>
                                    </svg>
                                    {{property.address.line_1}} {{property.address.postal_code}} {{property.address.city}} {{property.address.state_province_name}} {{property.address.country_code}}
                                </p>

                                <PropertyRates
                                    :score_description="property.static.score_description"
                                    :salabamScore="property.static.salabamScore"
                                    :hotelRating="property.static.hotelRating"
                                />
                            </div>
                        </div>
                    </b-col>

                    <b-col md="4" xl="3">
                        <div class="map-link" @click="mixinGoTo('map-detail',{
                            slug: (property.name).replace(/ /g, '-'),
                            title: property.name,
                            propertyId: parseInt(propertyId),
                            property: propertyPad
                        })">
                            <SvgMapLink />
                            <span>Vedi mappa</span>
                        </div>
                    </b-col>
                </b-row>

                <div class="property__highlight">

                    <div class="highlight__left">
                        <div class="slick-container">
                            <Slick
                                id="slickTop"
                                ref="slickTop"
                                :options="slickOptions"
                            >
                                <div v-for="(value,imageIndex) in (property.images).slice(0,100)" v-bind:key="imageIndex">
                                    <div class="property-image" :style="'background-image:url('+value.src+')'" @click="toggleInternalModal('slickTopFullwidth',true,imageIndex)">
                                        <span class="slick-caption">{{value.caption}}</span>
                                    </div>
                                </div>
                            </slick>
                            <span variant="light" class="slick-arrow" id="slick-prev"><SvgChevronLeft /></span>
                            <span variant="light" class="slick-arrow" id="slick-next"><SvgChevronRight /></span>
                       </div>

                        <b-modal id="slickTopFullwidth" data-class="internalGallery" ref="slickTopFullwidth" size="xl" hide-header hide-footer>
                            <div class="d-block text-center slick-container">
                                <Slick
                                    :options="{
                                        dots:true,
                                        fade:true,
                                        prevArrow: '#slick-prev-internal',
                                        nextArrow: '#slick-next-internal',
                                        asNavFor: '#slickTop',
                                        initialSlide: firstSlideIndex
                                    }"
                                >
                                    <div v-for="(value,imageModalIndex) in (property.images).slice(0,100)" v-bind:key="imageModalIndex" class="internalImage">
                                        <div class="slick-caption"><span>{{value.caption}}</span></div>
                                        <img :src="value.src">
                                    </div>
                                </slick>
                                <span variant="light" class="slick-arrow slick-prev" id="slick-prev-internal"><SvgChevronLeft /></span>
                                <span variant="light" class="slick-arrow slick-next" id="slick-next-internal"><SvgChevronRight /></span>
                            </div>
                            <div class="closeInternal" @click="toggleInternalModal('slickTopFullwidth',false,imageModalIndex)">
                                <SvgCross />
                            </div>
                        </b-modal>
                    </div>

                    <div class="highlight__right">

                        <!-- obfuscated è diventato obfuscation_required in ra2.4 con questo trick non devo fare una nuova versione-->
                        <div @click="mixinGoTo('map-detail',{
                                slug: (property.name).replace(/ /g, '-'),
                                title: property.name,
                                propertyId: parseInt(propertyId),
                                property: propertyPad
                            })"
                            class="cursor-pointer mt-0 property__address" v-if="!property.address.obfuscated && !property.address.obfuscation_required">
                            <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.49988 0.286011C2.69988 0.286011 0.399902 2.58605 0.399902 5.38605C0.399902 8.18605 3.39988 11.386 5.49988 13.186C7.59988 11.386 10.5999 8.18605 10.5999 5.38605C10.5999 2.58605 8.29988 0.286011 5.49988 0.286011ZM5.49988 6.58606C4.39988 6.58606 3.59985 5.68604 3.59985 4.68604C3.59985 3.68604 4.49988 2.78601 5.49988 2.78601C6.49988 2.78601 7.3999 3.68604 7.3999 4.68604C7.3999 5.68604 6.49988 6.58606 5.49988 6.58606Z" fill="#0071BC"/>
                            </svg>
                            {{property.address.line_1}} {{property.address.postal_code}} {{property.address.city}} {{property.address.state_province_name}} {{property.address.country_code}}

                            <br>
                            <div class="proximity__box" v-if="false">
                                <div class="property__proximity" v-if="proximityDistance >= 0 && geo.matchLevel == 'point'">
                                    <SvgProximity /> {{proximityDistance}} km dal punto selezionato
                                </div>
                                <div class="property__proximity" v-else-if="proximityDistance >= 0">
                                <SvgProximity /> {{proximityDistance}} km dal centro di {{geo.label}}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <b-row>
                            <b-col class="mr-0">
                            
                                <div class="property__amenities">
                                    <span v-for="(at,index) in (Object.values(property.details.propertyAmenities)).slice(0, 4)" v-bind:key="index" :class="'amenity '+(icons['amenities'][at.id] !== undefined ? icons['amenities'][at.id] : 'icon-default')">
                                        {{at.name}}
                                    </span>
                                </div>
                                <div
                                    @click="$bvModal.show('modal-property-attributes')"
                                    v-if="Object.values(property.details.propertyAmenities).length"
                                >
                                    <BtnChevronRight :text="'Tutti i servizi'" />
                                </div>                  
                                <b-modal id="modal-property-attributes" size="lg" hide-footer>
                                    <template v-slot:modal-title>
                                        Potrebbero prevedere supplementi da pagare in loco
                                    </template>
                                    <div class="modal-section">
                                        <div class="property__amenities">
                                            <span v-for="(at,index) in Object.values(property.details.propertyAmenities)" v-bind:key="index" :class="'amenity '+(icons['amenities'][at.id] !== undefined ? icons['amenities'][at.id] : 'icon-default')">
                                                {{at.name}}
                                            </span>
                                        </div>
                                    </div>
                                </b-modal>

                            </b-col>
                        </b-row>

                        <br>
                        <p class="reviewsTitle">
                            <small>
                            <strong>Valutazione dei viaggiatori</strong>
                            </small>
                        </p>

                        <!--<hr>-->

                        <Reviews :size="'compact'"/>

                        <!--<hr>-->

                        <p class="property__description">
                            <span v-if="(property.details.location).length<125">
                                {{ property.details.location }}
                            </span>
                            <span v-else>
                                {{ property.details.location.substring(0,125)+".." }}
                            </span>
                            <i @click="$bvModal.show('modal-property-details')"> dimmi di più</i>
                            <b-modal id="modal-property-details" size="lg" hide-footer>
                                <template v-slot:modal-title>
                                    Informazioni sulla struttura
                                </template>
                                <div class="modal-section">
                                    <div class="d-block text-center">
                                        <PropertyInfo :details="property.details" />
                                    </div>
                                </div>
                            </b-modal>
                        </p>

                    </div>

                </div>
            </div>
        </div>


        <!--<PropertyCovidAlert v-if="!loadingProperty && config.guiSettings.covidAlerTxt != '-'" :details="property.details" />-->
        <PropertyConditions />


        <div v-if="loadingRooms" class="hide-multiple-spinner">
            <Spinner />
        </div>

        <div v-else>

            <PropertyAmenitiesSlider :amenities="property.details.propertyAmenities" @get_modal_amenities="showModalAmenities" />

            <div class="rooms">
              <div class="panel"><h5 class="rooms__title">Scegli la tua camera</h5></div>
              <div class="search"><!-- nascosto form ricerca nei risultati perchè faceva capricci il calendario -->
                    <div class="panel-gray mt-4">
                        <SearchForm
                            :elementSlug="'detail-search'"
                            :offset="0"
                            :submitCallback="submitCallback"
                            :datePickerSelector="'searchFormPropoertyDetailDateicker'"
                            v-on:submit-search="updateSearch()"
                            v-on:filter-changed="this.$parent.filterChanged"
                            v-on:reset-results="this.$parent.resetResults"
                            v-on:reset-results-and-trigger="this.$parent.resetResultsAndTrigger"
                            v-on:search-form-is-valid="this.$parent.searchFormIsValid"
                        />
                    </div>
                    <b-alert v-if="oldSearchMessage" variant="danger" class="mb-4" show>
                      Per avviare una nuova ricerca devi modificare i parametri.
                    </b-alert>
                </div>
                <div v-if="rooms.length > 0">
                    <Room v-for="(room,i) in rooms" v-bind:key="i" :propertyDetails="property.details" :room="room" v-on:room-selected="roomBoxsetSelected" v-on:boxset-selected="roomBoxsetSelected" />
                </div>
                <b-alert variant="warning" class="mb-4" v-else show>
                    Non ci sono camere disponibli in questa struttura nelle date indicate.
                </b-alert>
            </div>
        </div>
        
        <div v-if="loadingProperty" class="hide-multiple-spinner">
            <Spinner />
        </div>

        <div v-else>
            <div class="info">
                <div class="info__panel">
                    <h6 class="info__panel-title">
                        Informazioni importanti
                    </h6>
                    <div class="info__panel-content">
                        <PropertyImportantInfo v-if="property.details.specialDetails" :specialDetails="property.details.specialDetails" />
                        &nbsp;
                        <PropertyToKnow :details="property.details" />
                    </div>
                    <!--<span class="d-flex justify-content-center mt-4" v-b-toggle.collapse-amenities>
                        <BtnChevronRight :text="'Leggi tutto'" />
                    </span>
                    <b-collapse id="collapse-amenities" class="mt-2">
                      <PropertyToKnow :details="property.details" />
                    </b-collapse>-->
                </div>
            </div>

            <b-row>
                <b-col sm="12" class="text-center">
                    <router-link :to="{ name: 'search'}">
                        <BtnBack v-if="rooms.length > 0" :text="'Torna ai risultati'" />
                        <BtnBack v-else :text="'Torna alla ricerca'" />
                    </router-link>
                </b-col>
            </b-row>
        </div>
     
    </div>
</template>

<script>
import Vue from 'vue'
import BtnBack from '../atoms/btn-back'
import Api from '../../api'
import { mapGetters } from 'vuex'
import SearchForm from '../blocks/search-form/search-form'
//import SvgMapSmall from '../svg/map-small'
import Stars from '../atoms/stars'
import BtnChevronRight from '../atoms/btn-chevron-right'
import Room from '../blocks/room'
import PropertyInfo from '../blocks/property/property-info'
import PropertyToKnow from '../blocks/property/property-toKnow'
//import PropertyCovidAlert from '../blocks/property/property-covid-alert'
import PropertyConditions from '../blocks/property/property-conditions'
import PropertyImportantInfo from '../blocks/property/property-important-info'
import PropertyRates from '../blocks/property/property-rates'
import PropertyAmenitiesSlider from '../blocks/property/property-amenities-slider'
import Spinner from '../atoms/spinner'
import Slick from 'vue-slick'
import SvgChevronRight from '../svg/chevron-right'
import SvgChevronLeft from '../svg/chevron-left'
import SvgCross from '../svg/cross'
import SvgInfoCircle from './../svg/info-circle'
import Reviews from '../blocks/property/property-reviews'
import SvgMapLink from './../svg/map-link'

export default {
    name: 'property',
    metaInfo () {
      return {
        title: (this.propertyName !== '') ? this.propertyName : 'salabam',
      }
    },
    components: {
        SearchForm,
        //SvgMapSmall,
        BtnBack,
        BtnChevronRight,
        Stars,
        Room,
        PropertyInfo,
        PropertyToKnow,
        PropertyImportantInfo,
        //PropertyCovidAlert,
        PropertyConditions,
        PropertyRates,
        PropertyAmenitiesSlider,
        Spinner,
        Slick,
        SvgChevronLeft,
        SvgChevronRight,
        SvgCross,
        SvgInfoCircle,
        Reviews,
        SvgMapLink,
    },
    //props: ["propertyId", "propertyPad", "propertyName"],
    props:
    {
        propertyBand: String,
        propertyId: String,
        propertyPad: Object,
        propertyName: {
            type: String,
            default: '',
        },
    },
    computed:{
        ...mapGetters({
            geo:   'getGeo',
            checkin:   'getCheckin',
            checkout:   'getCheckout',
            //nights:  'getNights',
            occupancy: 'getOccupancy',
            affiliateReferenceId: 'getAffiliateReferenceId',
            //activeBand: 'getActiveBand',
            nightlyPrice: 'getNightlyPrice',
        }),
    },
    watch: {
        propertyId: function () {
            this.getPropertyDetails()
        }
    },
    data() {
        return {
            imageModalIndex: 0,
            firstSlideIndex: 0,
            config: Vue.prototype.$config,
            icons:              Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
            loadingProperty: true,
            loadingRooms: true,
            property: {},
            rooms: {},
            initialSearch: {},
            slide: 0,
            slickOptions: {
                    dots:true,
                    fade:true,
                    prevArrow: '#slick-prev',
                    nextArrow: '#slick-next',
                },
            focus: '',
            nights: 0,
            activeBand: '',
            submitCallback: function()
            {
                // questo form non prevede nessun redirect
                // this.mixinGoTo('search',{})
            },

            detailsSearchParams: {},
            oldSearchMessage : false,
        }
    },
    mounted() {
        this.setResumeInfo()
        this.getPropertyDetails()
    },
    methods: {
        goBack()
        {
            this.$router.go(-1)
        },
        setResumeInfo()
        {
            //this.propertyBand = this.$store.state.activeBand
            this.focus = this.$store.state.focus
            this.nights = this.$store.state.dates.nights
            this.activeBand = this.$store.state.activeBand
        },        
        getPropertyDetails()
        {
            let _this = this
                this.loadingProperty = true,
                this.loadingRooms = true,
                this.oldSearch = JSON.stringify({
                    checkin: _this.checkin,
                    checkout: _this.checkout,
                    nights: _this.nights,
                    occupancy: _this.occupancy,
                    propertyId: parseInt(_this.propertyId),
                    band: _this.propertyBand,
                    nightlyPrice: _this.nightlyPrice,
                })

            let parameters = {
                checkin: _this.checkin,
                checkout: _this.checkout,
                nights: _this.nights,
                occupancy: _this.occupancy,
                propertyId: parseInt(_this.propertyId),
                band: _this.propertyBand,
                nightlyPrice: _this.nightlyPrice,
            }
            Api
                .getDetails(parameters)
                .then((data) => {
                    _this.parseProperty(data.content)
                    _this.parseRooms(data)
                    _this.updateDetailsSearchParams(data)
                })
        },
        updateDetailsSearchParams(data){
          // salvo i parametri di ricerca che ritornano dal dettaglio
          // nelle conferme DEVONO essere mostrati questi, non quelli del form
          // (ogni tanto c'è qualche furbo che cerca una data, poi dal dettaglio cambia data
          // e clicca prenota senza fare la ricerca, la conferma mostra la nuova data ma in
          // realtà sta prenotando quella cercata prima)
          this.detailsSearchParams = {
            checkin: data.checkin,
            checkout: data.checkout,
            nights: data.nights,
            occupancy: data.occupancy
          }
        },
        // replicato in room
        toggleInternalModal(refModal, open, start)
        {
            let _this = this
            _this.firstSlideIndex = start
            if(open)
            {
                _this.$refs[refModal].show()
            }
            else
            {
                _this.$refs[refModal].hide()
            }
        },
        updateSearch()
        {
            let _this = this,
                scroll = window.pageYOffset,
                pageWrapper = document.getElementById('property-details'),
                pageWrapperHeight = pageWrapper.clientHeight,
                parameters = {
                    checkin: _this.checkin,
                    checkout: _this.checkout,
                    nights: _this.nights,
                    occupancy: _this.occupancy,
                    propertyId: parseInt(_this.propertyId),
                    band: _this.propertyBand,
                    nightlyPrice: _this.nightlyPrice,
                },
                newSearch = JSON.stringify(parameters)
                pageWrapper.style.minHeight = pageWrapperHeight+'px';

            // evita load more e append, altrimenti l'utente non capisce cosa sta succedendo
            // avvio la ricerca SOLO se i criteri sono cambiati
            if(newSearch == _this.oldSearch) {
              this.oldSearchMessage = true
              return
            }

            _this.oldSearch = newSearch
            _this.loadingRooms = true
            _this.$parent.getResults()
            this.oldSearchMessage = false

            Api
                .getDetails(parameters)
                .then((data) => {
                    //window.console.log('updateDetail')
                    _this.parseProperty(data.content)
                    _this.parseRooms(data)
                    _this.updateDetailsSearchParams(data)
                    window.scrollTo(0, scroll)
                })
        },
        parseProperty(propertyData)
        {
            this.loadingProperty = true
            this.property.static = propertyData.static
            this.property.address = propertyData.address
            this.property.phone = propertyData.phone
            this.property.details = propertyData.details
            this.property.amenities = Object.values(propertyData.details.propertyAmenities)
            this.property.category = propertyData.category
            propertyData.images.forEach(image => {
                if (image.links['1000px'] !== undefined) image.src = image.links['1000px'].href
                else if (image.links['350px'] !== undefined) image.src = image.links['350px'].href
                else if (image.links['200px'] !== undefined) image.src = image.links['200px'].href
                else image.src = image.links['70px'].href
            })
            this.property.images = propertyData.images
            this.property.location = propertyData.location
            this.property.name = propertyData.name
            this.property.stars = {
                rating: propertyData.stars.rating,
                type: propertyData.stars.type,
                intval: Math.floor(parseFloat(propertyData.stars.rating)),
                floatval: parseFloat(propertyData.stars.rating)%1
            }
            this.property.statistics = propertyData.statistics
            this.loadingProperty = false
        },
        parseRooms(roomData)
        {
            // caso in cui dalla pagina dettaglio ho cambiato date/pax e non c'è più disponibilità
            if(!roomData.availabilities.rooms)
            {
                //window.console.log('no rooms')
                this.rooms = []
                this.loadingRooms = false
                return
            }            
            this.loadingRooms = true
            this.rooms = Object.values(roomData.availabilities.rooms)
            this.rooms.forEach(room => {
                room.images = []
                let contentRoom = roomData.content.rooms[room.id]

                if(!contentRoom){
                  //window.console.log('missing room.id: ', room.id)
                  return
                }

                if(contentRoom.images)
                {
                    contentRoom.images.forEach(image => {
                        let imageSrc = ''
                        if (image.links['1000px'] !== undefined) imageSrc = image.links['1000px'].href
                        else if (image.links['350px'] !== undefined) imageSrc = image.links['350px'].href
                        else if (image.links['200px'] !== undefined) imageSrc = image.links['200px'].href
                        else imageSrc = image.links['70px'].href
                        room.images.push({
                            src: imageSrc,
                            caption: image.caption
                        })
                    })
                }
                room.amenities = contentRoom.amenities
                room.bed_groups = contentRoom.bed_groups
                room.descriptions = contentRoom.descriptions
                room.name = contentRoom.name
                room.occupancy = contentRoom.occupancy
            })
            this.loadingRooms = false
        },
        roomBoxsetSelected(e) {
            let room = this.rooms.filter(function (el) {
                return el.id == e.roomId
            })[0]
            let rates = Object.keys(room.rates).map(key => {
                return room.rates[key];
            })
            let rate = rates.filter(function (el) {
                return el.id == e.rateId
            })[0]
            let bookingData = {}
            bookingData.hotelId = this.propertyId
            bookingData.productid = ''
            bookingData.nightlyRate = ''

            // detailsSearchParams
            bookingData.arrivalDate = this.detailsSearchParams.checkin
            bookingData.departureDate = this.detailsSearchParams.checkout
            bookingData.nights = this.detailsSearchParams.nights
            bookingData.numberOfAdults = this.detailsSearchParams.occupancy.adults
            bookingData.childs = this.detailsSearchParams.occupancy.children
            bookingData.numberOfChildren = this.detailsSearchParams.occupancy.children.length

            bookingData.roomDescription = room.name
            bookingData.roomDescriptionLong = (room.descriptions && room.descriptions.overview)? room.descriptions.overview : ''
            bookingData.bedTypeId = rate.bedding[e.bedKey].pricecheck_token
            bookingData.bedType = rate.bedding[e.bedKey].label
            bookingData.roomTypeCode = ''
            bookingData.roomAmenities = room.amenities
            bookingData.roomId = e.roomId
            bookingData.rateId = e.rateId
            bookingData.live_price = rate.live_price
            
            /* TODO SALABAM dentro boxsetsConfig*/
            bookingData.boxset_id = Vue.prototype.$config.boxsetsConfig[this.focus][this.propertyBand][this.nights].boxset_id//boxsetsConfig.italia.standard.3.boxset_id
            bookingData.public_price = rate.public_price//boxsetsConfig.italia.standard.3.public_price
            bookingData.focus = this.focus//boxsetsConfig.italia
            bookingData.band = this.propertyBand//boxsetsConfig.italia.standard
            bookingData.nights = this.nights;//boxsetsConfig.italia.standard.nights

            bookingData.payments = {
                'welfare': {
                    'amount': 0,
                },
                'cc': {
                    'amount': 0,
                    'nameSurname': '',
                    'paymentIntentId': ''
                },
                'etc': {
                    'amount': 0,
                    'vouchers': [],
                },
                'discountCodes': {
                  'amount': 0,
                  'codes': [],
                }
            },

            bookingData.calculatedTotal = ''
            bookingData.surchargeTotal = ''
            bookingData.currencyCode = 'EUR'
            bookingData.smokingPreferences = 'NC'
            bookingData.txtSmokingPreferences = 'non fumatori'
            bookingData.hotelName = this.property.name
            bookingData.hotelCity = this.property.address.city
            bookingData.hotelPhone = this.property.phone
            bookingData.hotelAddress = this.property.address.line_1
            bookingData.hotelPostalCode = this.property.address.postal_code
            bookingData.hotelCountryCode = this.property.address.country_code
            bookingData.hotelDetails = this.property.details
            bookingData.thumbNailUrl = '' // TODO
            bookingData.rateCheck = '' // TODO
            bookingData.upselling_amount = 0 // TODO
            bookingData.listPos = 0 // TODO
            bookingData.searchLogId = 0 // TODO
            bookingData.refundable = rate.refundable
            bookingData.refundableBefore = rate.refundableBefore

            bookingData.ck_sortFilterFromPolygon = '' // TODO

            // TODO
            // bookingData.cacheKeysUsed = [Objct Object] // preview.cks

            bookingData.affiliateReferenceId = this.affiliateReferenceId
            bookingData.chargeableRate = rate.totals.inclusive //<!-- serviranno solo per vadobay
            bookingData.marketingFee = rate.totals.marketing_fee
            bookingData.totals = rate.totals
            bookingData.rateAmenities = rate.amenities
            bookingData.rateAmenitiesEps = rate.amenitiesEps
            
            this.mixinSendMutation('setBookingData', bookingData)

            let _this = this
            setTimeout(function(){

                if(_this.mixinGetIntegrationInfo(0).mode == 'booking')
                {
                    _this.$router.push({ name: 'checkout' })
                }
                
                if(_this.mixinGetIntegrationInfo(0).mode == 'boxset')
                {
                    let boxset = _this.$config.boxsetsConfig[_this.focus][_this.activeBand][_this.nights]
                    _this.mixinBoxsetSelected(boxset)
                    if(_this.mixinGetIntegrationInfo().sso == true)
                    {
                        _this.$router.push({ name: 'checkout' })
                        return
                    }
                    _this.$router.push({ name: 'before-checkout' })
                }
            },100)
        },
        showModalAmenities(){
          this.$root.$emit('bv::show::modal', 'modal-property-attributes')
        }
    }
}
</script>


<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './src/scss/_custom.scss';

#property-details
{

    @include media-breakpoint-down(xs)
    {
        .reviewsTitle
        {
            text-align:center;
        }
    }

    .hide-multiple-spinner + .hide-multiple-spinner
    {
        display:none !important;
    }

    .map-link
    {
        @include media-breakpoint-down(md){
            margin-top: 20px;
        }
        
        svg
        {
            width:100%;
            height:auto;
        }
    }

    .topBtn
    {
        margin-bottom: 20px;
    }

    .propertyHeading
    {
        margin-top:0.25rem;
        margin-bottom:1.85rem;
        align-items: center;

        @include media-breakpoint-up(lg)
        {
            .property__address
            {
                display:none;
            }
        }
    }

    .topProperty
    {
        justify-content: flex-start;
        align-items: center;
        display: flex;        
        .property__scoredesc
        {
            //padding:10px 0;
            font-weight:500;
            text-transform: uppercase;
            font-size:14px;
            margin-right:12px;
        }
    }

    .property__top
    {
        display:flex;
        justify-content: center;
        align-items: center;
        //margin-top:16px;
        //margin-bottom:10px;
        //box-shadow:$box-shadow;
        background:$white;
        //padding:14px 18px;
        &>div
        {
            width:100%;
        }
        .d-flex
        {
            flex-wrap: wrap;
        }
        @include media-breakpoint-up(lg)
        {
            margin-top:0;
            .d-flex
            {
                flex-wrap: nowrap;
                //justify-content:space-between;
                width:100%;
            }
        }
        @include media-breakpoint-up(xl)
        {
            //padding:28px 36px;
            //margin-top:26px;
        }

        .property__rates
        {
            margin-top:4px;
        }
        .property__address
        {
            margin-top:4px;
            margin-bottom:0;
            color:$gray-500;
            svg
            {
                margin-right:4px;
                *{fill:$gray-500;}
            }
        }
    }

    .boxsetPadPropertyColumn
    {
        display:flex;
        flex-direction:column;
        justify-content:center;
        .infoCircle
        {
            width:16px;
            margin-bottom:4px;
            path
            {
                fill:$white;
            }
        }
        .boxsetPadProperty
        {
            position:relative;
            padding:.5rem 1rem;
            margin-top:1rem;
            margin-bottom:1rem;
            font-weight: bold;
            color:$white;
            text-align:center;
            @include media-breakpoint-up(lg)
            {
                padding:1rem 1.25rem;
                text-align:left;
                margin-top:0;
            }
        }
        .triangleRight
        {
            display:none;
            position:absolute;
            transform:translateY(-50%);
            top:50%;
            left:100%;
            width: 0; 
            height: 0; 
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 14px solid green;
        }
        @include media-breakpoint-up(xl)
        {
            padding-right:0;
            
            .triangleRight
            {
                display:block;
            }
        }
    }

    .property__heading
    {
        color:$black;
        font-size:20px;
        font-weight:600;
        margin-bottom:0;
        margin-right:1rem;
        width:100%;
        @include media-breakpoint-up(xl)
        {
            width:auto;
            font-size:25px;
        }
    }

    .property__highlight
    {
        margin-bottom:$grid-gutter-width/4;
        //box-shadow:$box-shadow;
        //background:$light-gray;
        //border-bottom-left-radius: $border-radius;
        //border-bottom-right-radius: $border-radius;
        overflow: hidden;
        .btn-chevron-right
        {
            margin-top:0.5rem;
            justify-content: flex-end;
        } 
        .highlight__right
        {
            padding:$grid-gutter-width/2;
            hr
            {
                margin-top:0.5rem;
                margin-bottom:0.5rem;
            }
            svg
            {
                //height:86px;
            }

            .proximity__box
            {
                color:$black;
                display: flex;
                font-size:11px;
                margin-top:3px;
                svg
                {
                    height: auto;
                    width: 9px;
                    margin-right: 6px;
                    margin-left: 2px;
                    path
                    {
                        fill:$black;
                    }
                }
            }

            @include media-breakpoint-down(xs)
            {
                padding-left: 0;
                padding-right: 0;
            }            
        }
        @include media-breakpoint-up(lg)
        {
            display:flex;
            .highlight__left
            {
                width:50%;
            }
            .highlight__right
            {
                padding:0 0 $grid-gutter-width/2 $grid-gutter-width/2;
                width:50%;
                hr
                {
                    margin-top:1.5rem;
                    margin-bottom:1.5rem;
                }
            }
        }
    }

    .map-detail
    {
        cursor:pointer;
        svg
        {
            display:block;
        }
    }

    .property__amenities
    {
        display:flex;
        flex-wrap: wrap;
        .amenity
        {
            //font-size:17px;
            font-weight:500;
            width:auto;
            width:50%;
            min-width:50%;
            max-width:50%;
            flex:50%;
            line-height: normal;
            align-items: flex-start;

            &:before {
                margin-top: 3px;
            }
        }
    }

    .property-image
    {
        width:100%;
        //height:100%;
        min-height:220px;
        background-size:cover;
        background-position:center center;
        @include media-breakpoint-up(md)
        {
            min-height:320px;
        }
        @include media-breakpoint-up(lg)
        {
            min-height:440px;
        }
    }

    .property__address
    {
        margin-top:1rem;
        color:$secondary;
        font-size:12px;
        font-weight:400;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        svg
        {
            margin-right:8px;
        }
        @include media-breakpoint-up(md)
        {
            font-size:15px;
        }
    }

    .property__description
    {
        font-size:15px;
        color:$smooth-black;
        font-weight:400;
        margin-bottom:0;
        i
        {
            cursor:pointer;
            color:$secondary;
            font-weight:500;
        }
    }

    .detail-first-title
    {
        margin-top:1rem;
        //font-size: $font-size-sm;
        color:$secondary;
    }
    .detail-first-content
    {
        //font-size: $font-size-sm;
    }

    .rooms__title
    {
        display:block;
        padding:0;//$grid-gutter-width/2;
        color:$black;
        font-weight:600;
        font-size: 20px;
        margin-bottom:0;
        @include media-breakpoint-up(xl)
        {
            font-size: 25px;
        }
    }

    .info__panel
    {
        padding:$grid-gutter-width/2;
        border-radius:$border-radius;
        box-shadow:$box-shadow;
        margin-bottom:$grid-gutter-width/2;
        background:$white;
        .info__panel-title
        {
            font-weight:600;
            font-size:25px;
            color:$black;
            margin-bottom:1rem;
        }
        .info__panel-content
        {
            color:$smooth-black;
            font-size: 15px;
            font-weight:400;
            line-height:160%;
        }
    }

    .stars
    {
        transform:none;
        margin-left:0.5rem;
        span
        {
            width:16px;
            margin-right:4px;
        }
    }

    .search
    {
        margin-bottom:0;
        #search-form
        {
            background:transparent;
            padding:0;
            input,select,.dropdown,#filters button
            {
                //border:1px solid $medium-gray;
            }
            #search-form-autocomplete,
            #filters
            {
                display:none !important;
            }
        }
    }

    .slick-slide
    {
        cursor:pointer;
    }

    @include media-breakpoint-down(md)
    {
        .property__rates
        {
            margin-top:0;
        }
        .stars
        {
            margin-left:0;
            span
            {
                width:14px;
            }
        }
    }
}

div[data-class=internalGallery]
{
    .modal-dialog
    {
        max-width: calc(100% - 16px);
    }

    .closeInternal
    {
        position:fixed;
        top:0;
        right:0;
        color:$white;
        padding:1rem;
        font-size: 24px;
        cursor:pointer;
        z-index: 99999;
    }
    .modal.show
    {
        display:flex !important;
    }
    .modal-dialog
    {
        flex:1;
        margin-top:1rem;
        margin-bottom:1rem;
    }
    .modal-content
    {
        //border-radius:$border-radius;
        //overflow:hidden;
        background-color:transparent;
    }
    .modal-content,
    .slick-container,
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide div
    {
        height:100%;
    }
    .slick-slide
    {
        position:relative;
        //height:100px;
        //overflow:hidden;
        //float:none;
    }
    .internalImage
    {
        display:flex !important;
        justify-content: center;
        align-items:center;
        align-content:center;
        padding-top:2rem;
        padding-bottom:2rem;
        img
        {
            width:auto;
            margin:0 auto;
            max-width: 100%;
        }
    }
    .slick-caption
    {
        height: auto !important;
        background:transparent;
        position:absolute;
        top:0;
        width:100%;
        left:0;
        right:0;
        font-size: 12px;
        color:$white;
        span
        {
            //background:$secondary;
        }
        text-align:center;
        @include media-breakpoint-up(md)
        {
            font-size:18px;
        }
    }
    .slick-dots
    {
        //position:relative;
        bottom:0;
    }
}

.modal-content
{
    .property__amenities
    {
        display:flex;
        flex-wrap:wrap;
    }
    .info__panel-title
    {
        font-weight:600;
        font-size:25px;
        color:$black;
        margin-bottom:1rem;
    }
    .info__panel-content
    {
        color:$smooth-black;
        font-size: 15px;
        font-weight:400;
        line-height:160%;
    }
}

.property-details-modal
{
    text-align:left;
    p
    {
      font-size: 15px;
      font-weight:300;
      color:$black;
    }
    &:first-child .property-detail-title
    {
        margin-top:0;
    }
    .property-detail-title
    {
        margin-top:1rem;
        //font-size: $font-size-sm;
        color:$black;
    }
    .property-detail-content
    {
        //font-size: $font-size-sm;
    }
    ul
    {
        margin-bottom:0.75rem;
    }
    li
    {
        padding-left:1rem;
        margin-left:1rem;
        position:relative;
        display:block;
        width:100%;
        &:before
        {
            content:"";
            position:absolute;
            left:0;
            top:50%;
            transform:translateY(-50%);
            height:5px;
            width:5px;
            border-radius:50%;
            background:$black;
        }
    }
}

.rooms
{
    @include media-breakpoint-down(xs)
    {
        &>.panel
        {
            padding:$grid-gutter-width/4;
            text-align:center;
        }
    }
    .search .panel-gray
    {
        display:none;
    }
    &>.panel
    {
        margin-bottom:$grid-gutter-width/2;
    }
    @include media-breakpoint-up(xl)
    {
        .search .panel-gray
        {
            display:flex;
            #search-form-buttons
            {
                flex:13% !important;
                max-width:13% !important;
            }
            #search-form-occupancy,
            #search-form-nights,
            #search-form-datepicker
            {
                flex:29% !important;
                max-width:29% !important;
            }
        }
        &>.panel
        {
            margin-bottom:0;
        }  
    }
}

.rooms #search-form
{
    width:100%;
    min-width:100%;
    #search-form-buttons
    {
        padding-right: 0;
    }
}

.rooms #search-form form
{
    @include media-breakpoint-up(lg)
    {
        #search-form-datepicker,
        #search-form-occupancy,
        #search-form-buttons,
        #search-form-nights
        {
            flex:25%;
            max-width:25%
        }
    }
}



@include media-breakpoint-up(lg)
{
    #app.property #appHeader,
    #app.map-detail #appHeader,
    {
        display:none;
    }
}
</style>