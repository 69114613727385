import { render, staticRenderFns } from "./property-amenities-slider.vue?vue&type=template&id=f3b15444&"
import script from "./property-amenities-slider.vue?vue&type=script&lang=js&"
export * from "./property-amenities-slider.vue?vue&type=script&lang=js&"
import style0 from "./property-amenities-slider.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/salabam.com/cancellara-src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f3b15444')) {
      api.createRecord('f3b15444', component.options)
    } else {
      api.reload('f3b15444', component.options)
    }
    module.hot.accept("./property-amenities-slider.vue?vue&type=template&id=f3b15444&", function () {
      api.rerender('f3b15444', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/blocks/property/property-amenities-slider.vue"
export default component.exports