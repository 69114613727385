var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "property-details", "data-e2e": "propertyDetails" } },
    [
      _vm.loadingProperty
        ? _c(
            "div",
            { staticClass: "hide-multiple-spinner" },
            [_c("Spinner")],
            1
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "property" },
              [
                _c(
                  "b-row",
                  { staticClass: "propertyHeading" },
                  [
                    _c("b-col", { staticClass: "topBtn col-12" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        },
                        [_c("BtnBack", { attrs: { text: "Torna indietro" } })],
                        1
                      ),
                    ]),
                    _vm.mixinGetIntegrationInfo(0).mode == "boxset"
                      ? _c(
                          "b-col",
                          {
                            staticClass: "boxsetPadPropertyColumn",
                            attrs: { lg: "12", xl: "3" },
                          },
                          [
                            _c("div", { staticClass: "boxsetPadProperty" }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value:
                                        "Le disponibilità sono reali e, quindi, dinamiche. Acquista questo voucher ora se vuoi prenotare subito questa struttura",
                                      expression:
                                        "'Le disponibilità sono reali e, quindi, dinamiche. Acquista questo voucher ora se vuoi prenotare subito questa struttura'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                },
                                [
                                  _c("SvgInfoCircle", {
                                    staticClass: "infoCircle",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(
                                "\n                        Con il Salabam\n                        "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("capitalize")(_vm.focus)) + " "
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("uppercase")(_vm.propertyBand)
                                  ) + " "
                                ),
                              ]),
                              _c("span", [_vm._v(_vm._s(_vm.nights) + " ")]),
                              _vm.nights == 1
                                ? _c("span", [_vm._v("notte ")])
                                : _c("span", [_vm._v("notti ")]),
                              _vm._v(
                                "\n                        potrai prenotare:\n                        "
                              ),
                              _c("div", { staticClass: "triangleRight" }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      {
                        staticClass: "topProperty",
                        attrs: {
                          md: "8",
                          xl:
                            _vm.mixinGetIntegrationInfo(0).mode == "boxset"
                              ? 6
                              : 8,
                        },
                      },
                      [
                        _c("div", { staticClass: "property__top" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "property__heading mr-2" },
                                    [_vm._v(_vm._s(_vm.property.name))]
                                  ),
                                  _c("Stars", {
                                    attrs: {
                                      hotelRating: parseFloat(
                                        _vm.property.stars.intval +
                                          "." +
                                          _vm.property.stars.floatval
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              !_vm.property.address.obfuscated &&
                              !_vm.property.address.obfuscation_required
                                ? _c(
                                    "p",
                                    { staticClass: "property__address" },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "11",
                                            height: "14",
                                            viewBox: "0 0 11 14",
                                            fill: "none",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M5.49988 0.286011C2.69988 0.286011 0.399902 2.58605 0.399902 5.38605C0.399902 8.18605 3.39988 11.386 5.49988 13.186C7.59988 11.386 10.5999 8.18605 10.5999 5.38605C10.5999 2.58605 8.29988 0.286011 5.49988 0.286011ZM5.49988 6.58606C4.39988 6.58606 3.59985 5.68604 3.59985 4.68604C3.59985 3.68604 4.49988 2.78601 5.49988 2.78601C6.49988 2.78601 7.3999 3.68604 7.3999 4.68604C7.3999 5.68604 6.49988 6.58606 5.49988 6.58606Z",
                                              fill: "#0071BC",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.property.address.line_1) +
                                          " " +
                                          _vm._s(
                                            _vm.property.address.postal_code
                                          ) +
                                          " " +
                                          _vm._s(_vm.property.address.city) +
                                          " " +
                                          _vm._s(
                                            _vm.property.address
                                              .state_province_name
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.property.address.country_code
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("PropertyRates", {
                                attrs: {
                                  score_description:
                                    _vm.property.static.score_description,
                                  salabamScore:
                                    _vm.property.static.salabamScore,
                                  hotelRating: _vm.property.static.hotelRating,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("b-col", { attrs: { md: "4", xl: "3" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "map-link",
                          on: {
                            click: function ($event) {
                              _vm.mixinGoTo("map-detail", {
                                slug: _vm.property.name.replace(/ /g, "-"),
                                title: _vm.property.name,
                                propertyId: parseInt(_vm.propertyId),
                                property: _vm.propertyPad,
                              })
                            },
                          },
                        },
                        [_c("SvgMapLink"), _c("span", [_vm._v("Vedi mappa")])],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "property__highlight" }, [
                  _c(
                    "div",
                    { staticClass: "highlight__left" },
                    [
                      _c(
                        "div",
                        { staticClass: "slick-container" },
                        [
                          _c(
                            "Slick",
                            {
                              ref: "slickTop",
                              attrs: {
                                id: "slickTop",
                                options: _vm.slickOptions,
                              },
                            },
                            _vm._l(
                              _vm.property.images.slice(0, 100),
                              function (value, imageIndex) {
                                return _c("div", { key: imageIndex }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "property-image",
                                      style:
                                        "background-image:url(" +
                                        value.src +
                                        ")",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleInternalModal(
                                            "slickTopFullwidth",
                                            true,
                                            imageIndex
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "slick-caption" },
                                        [_vm._v(_vm._s(value.caption))]
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "slick-arrow",
                              attrs: { variant: "light", id: "slick-prev" },
                            },
                            [_c("SvgChevronLeft")],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "slick-arrow",
                              attrs: { variant: "light", id: "slick-next" },
                            },
                            [_c("SvgChevronRight")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-modal",
                        {
                          ref: "slickTopFullwidth",
                          attrs: {
                            id: "slickTopFullwidth",
                            "data-class": "internalGallery",
                            size: "xl",
                            "hide-header": "",
                            "hide-footer": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-block text-center slick-container",
                            },
                            [
                              _c(
                                "Slick",
                                {
                                  attrs: {
                                    options: {
                                      dots: true,
                                      fade: true,
                                      prevArrow: "#slick-prev-internal",
                                      nextArrow: "#slick-next-internal",
                                      asNavFor: "#slickTop",
                                      initialSlide: _vm.firstSlideIndex,
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.property.images.slice(0, 100),
                                  function (value, imageModalIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: imageModalIndex,
                                        staticClass: "internalImage",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "slick-caption" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(value.caption)),
                                            ]),
                                          ]
                                        ),
                                        _c("img", {
                                          attrs: { src: value.src },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "slick-arrow slick-prev",
                                  attrs: {
                                    variant: "light",
                                    id: "slick-prev-internal",
                                  },
                                },
                                [_c("SvgChevronLeft")],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "slick-arrow slick-next",
                                  attrs: {
                                    variant: "light",
                                    id: "slick-next-internal",
                                  },
                                },
                                [_c("SvgChevronRight")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "closeInternal",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleInternalModal(
                                    "slickTopFullwidth",
                                    false,
                                    _vm.imageModalIndex
                                  )
                                },
                              },
                            },
                            [_c("SvgCross")],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "highlight__right" },
                    [
                      !_vm.property.address.obfuscated &&
                      !_vm.property.address.obfuscation_required
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "cursor-pointer mt-0 property__address",
                              on: {
                                click: function ($event) {
                                  _vm.mixinGoTo("map-detail", {
                                    slug: _vm.property.name.replace(/ /g, "-"),
                                    title: _vm.property.name,
                                    propertyId: parseInt(_vm.propertyId),
                                    property: _vm.propertyPad,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "11",
                                    height: "14",
                                    viewBox: "0 0 11 14",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M5.49988 0.286011C2.69988 0.286011 0.399902 2.58605 0.399902 5.38605C0.399902 8.18605 3.39988 11.386 5.49988 13.186C7.59988 11.386 10.5999 8.18605 10.5999 5.38605C10.5999 2.58605 8.29988 0.286011 5.49988 0.286011ZM5.49988 6.58606C4.39988 6.58606 3.59985 5.68604 3.59985 4.68604C3.59985 3.68604 4.49988 2.78601 5.49988 2.78601C6.49988 2.78601 7.3999 3.68604 7.3999 4.68604C7.3999 5.68604 6.49988 6.58606 5.49988 6.58606Z",
                                      fill: "#0071BC",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.property.address.line_1) +
                                  " " +
                                  _vm._s(_vm.property.address.postal_code) +
                                  " " +
                                  _vm._s(_vm.property.address.city) +
                                  " " +
                                  _vm._s(
                                    _vm.property.address.state_province_name
                                  ) +
                                  " " +
                                  _vm._s(_vm.property.address.country_code) +
                                  "\n\n                        "
                              ),
                              _c("br"),
                              false
                                ? _c("div", { staticClass: "proximity__box" }, [
                                    _vm.proximityDistance >= 0 &&
                                    _vm.geo.matchLevel == "point"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "property__proximity",
                                          },
                                          [
                                            _c("SvgProximity"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.proximityDistance) +
                                                " km dal punto selezionato\n                            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm.proximityDistance >= 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "property__proximity",
                                          },
                                          [
                                            _c("SvgProximity"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.proximityDistance) +
                                                " km dal centro di " +
                                                _vm._s(_vm.geo.label) +
                                                "\n                            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _c("hr"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "mr-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "property__amenities" },
                                _vm._l(
                                  Object.values(
                                    _vm.property.details.propertyAmenities
                                  ).slice(0, 4),
                                  function (at, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: index,
                                        class:
                                          "amenity " +
                                          (_vm.icons["amenities"][at.id] !==
                                          undefined
                                            ? _vm.icons["amenities"][at.id]
                                            : "icon-default"),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(at.name) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              Object.values(
                                _vm.property.details.propertyAmenities
                              ).length
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$bvModal.show(
                                            "modal-property-attributes"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("BtnChevronRight", {
                                        attrs: { text: "Tutti i servizi" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-modal",
                                {
                                  attrs: {
                                    id: "modal-property-attributes",
                                    size: "lg",
                                    "hide-footer": "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "modal-title",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n                                    Potrebbero prevedere supplementi da pagare in loco\n                                "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c("div", { staticClass: "modal-section" }, [
                                    _c(
                                      "div",
                                      { staticClass: "property__amenities" },
                                      _vm._l(
                                        Object.values(
                                          _vm.property.details.propertyAmenities
                                        ),
                                        function (at, index) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              class:
                                                "amenity " +
                                                (_vm.icons["amenities"][
                                                  at.id
                                                ] !== undefined
                                                  ? _vm.icons["amenities"][
                                                      at.id
                                                    ]
                                                  : "icon-default"),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(at.name) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _vm._m(0),
                      _c("Reviews", { attrs: { size: "compact" } }),
                      _c(
                        "p",
                        { staticClass: "property__description" },
                        [
                          _vm.property.details.location.length < 125
                            ? _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.property.details.location) +
                                    "\n                        "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.property.details.location.substring(
                                        0,
                                        125
                                      ) + ".."
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                          _c(
                            "i",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-property-details"
                                  )
                                },
                              },
                            },
                            [_vm._v(" dimmi di più")]
                          ),
                          _c(
                            "b-modal",
                            {
                              attrs: {
                                id: "modal-property-details",
                                size: "lg",
                                "hide-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "modal-title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                                Informazioni sulla struttura\n                            "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("div", { staticClass: "modal-section" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-block text-center" },
                                  [
                                    _c("PropertyInfo", {
                                      attrs: { details: _vm.property.details },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
      _c("PropertyConditions"),
      _vm.loadingRooms
        ? _c(
            "div",
            { staticClass: "hide-multiple-spinner" },
            [_c("Spinner")],
            1
          )
        : _c(
            "div",
            [
              _c("PropertyAmenitiesSlider", {
                attrs: { amenities: _vm.property.details.propertyAmenities },
                on: { get_modal_amenities: _vm.showModalAmenities },
              }),
              _c(
                "div",
                { staticClass: "rooms" },
                [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "search" },
                    [
                      _c(
                        "div",
                        { staticClass: "panel-gray mt-4" },
                        [
                          _c("SearchForm", {
                            attrs: {
                              elementSlug: "detail-search",
                              offset: 0,
                              submitCallback: _vm.submitCallback,
                              datePickerSelector:
                                "searchFormPropoertyDetailDateicker",
                            },
                            on: {
                              "submit-search": function ($event) {
                                return _vm.updateSearch()
                              },
                              "filter-changed": this.$parent.filterChanged,
                              "reset-results": this.$parent.resetResults,
                              "reset-results-and-trigger":
                                this.$parent.resetResultsAndTrigger,
                              "search-form-is-valid":
                                this.$parent.searchFormIsValid,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.oldSearchMessage
                        ? _c(
                            "b-alert",
                            {
                              staticClass: "mb-4",
                              attrs: { variant: "danger", show: "" },
                            },
                            [
                              _vm._v(
                                "\n                  Per avviare una nuova ricerca devi modificare i parametri.\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.rooms.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.rooms, function (room, i) {
                          return _c("Room", {
                            key: i,
                            attrs: {
                              propertyDetails: _vm.property.details,
                              room: room,
                            },
                            on: {
                              "room-selected": _vm.roomBoxsetSelected,
                              "boxset-selected": _vm.roomBoxsetSelected,
                            },
                          })
                        }),
                        1
                      )
                    : _c(
                        "b-alert",
                        {
                          staticClass: "mb-4",
                          attrs: { variant: "warning", show: "" },
                        },
                        [
                          _vm._v(
                            "\n                Non ci sono camere disponibli in questa struttura nelle date indicate.\n            "
                          ),
                        ]
                      ),
                ],
                1
              ),
            ],
            1
          ),
      _vm.loadingProperty
        ? _c(
            "div",
            { staticClass: "hide-multiple-spinner" },
            [_c("Spinner")],
            1
          )
        : _c(
            "div",
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info__panel" }, [
                  _c("h6", { staticClass: "info__panel-title" }, [
                    _vm._v(
                      "\n                    Informazioni importanti\n                "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info__panel-content" },
                    [
                      _vm.property.details.specialDetails
                        ? _c("PropertyImportantInfo", {
                            attrs: {
                              specialDetails:
                                _vm.property.details.specialDetails,
                            },
                          })
                        : _vm._e(),
                      _vm._v("\n                     \n                    "),
                      _c("PropertyToKnow", {
                        attrs: { details: _vm.property.details },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { sm: "12" } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "search" } } },
                        [
                          _vm.rooms.length > 0
                            ? _c("BtnBack", {
                                attrs: { text: "Torna ai risultati" },
                              })
                            : _c("BtnBack", {
                                attrs: { text: "Torna alla ricerca" },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "reviewsTitle" }, [
      _c("small", [_c("strong", [_vm._v("Valutazione dei viaggiatori")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel" }, [
      _c("h5", { staticClass: "rooms__title" }, [
        _vm._v("Scegli la tua camera"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }